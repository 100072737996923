<template>
  <section>
    <div class="w-full box mt-2 p-4">
      <div class="w-full md:flex md:justify-between">
        <div class="flex items-center gap-2">
          <i class="pi pi-file"></i>
          <p class="text-lg font-bold">Contrato # {{ contratosStore._contrato.NumCto }}</p>
          <div :class="contratosStore._contrato.Status == 1 ? 'text-green-600' : 'text-red-600'" class="font-bold flex items-center gap-2">
            <div :class="contratosStore._contrato.Status == 1 ? 'bg-green-600' : 'bg-red-600'"  class="h-2 w-2 rounded-full"></div>
            <p>{{ contratosStore._contrato.Status == 1 ? 'Activo' : 'Inactivo' }}</p>
          </div>
        </div>
        <div class="flex gap-2" v-if="$can('pharmasan.ventas.contrato-precio.editar')">
          <Button icon="pi pi-copy" severity="success"  @click="onClickejemplo()" v-tooltip.left="'Crear copia con base a este contrato'" aria-label="Submit" />
          <Button icon="pi pi-pencil" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.editar', params: { id: $route.params.id } })" label="Editar contrato" aria-label="Submit" />
        </div>
      </div>
      <div class="w-full flex gap-12 mt-4">
        <div>
          <p class="text-gray-600 text-xs italic">Cliente</p>
          <p class="font-bold">{{ contratosStore._contrato.Cliente.CardName }}</p>
        </div>
        <div>
          <p class="text-gray-600 text-xs italic">Valor contrato</p>
          <p class="font-bold">{{ contratosStore._contrato.ValorTotal }}</p>
        </div>
        <div>
          <p class="text-gray-600 text-xs italic">Motivos autorizacion seleccionados</p>
          <div class="flex gap-2">
            <div v-for="motivo in contratosStore._contrato.CtoMotAutoriza" :key="motivo.Id" class="flex gap-2">
              <p class="font-bold">{{ motivo.MotAutoriza.Nombre }}</p>
              <p v-if="contratosStore._contrato.CtoMotAutoriza[contratosStore._contrato.CtoMotAutoriza.length - 1].Id !== motivo.Id">-</p>
            </div>
          </div>
        </div>
        <div>
          <p class="text-gray-600 text-xs italic">Eps</p>
          <div class="flex gap-2">
            <div v-for="contrato in contratosStore._contrato.CtoEps" :key="contrato" class="flex gap-2">
              <p class="font-bold">{{ contrato.Entidad }} {{ contratosStore._contrato.CtoEps[contratosStore._contrato.CtoEps.length - 1].Entidad === contrato.Entidad ? ' ' : ' - ' }} </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-gray-600 text-xs italic mt-4">Descripción</p>
        <p class="font-bold uppercase">{{ contratosStore._contrato.Descript }}</p>
      </div>
      <div class="w-full mt-4">
        <TabView>
          <TabPanel header="Zonificación">
            <Zonificacion />
          </TabPanel>
          <TabPanel header="Pacientes">
            <Pacientes/>
          </TabPanel>
          <TabPanel header="Articulos/Precios">
            <Precios/>
          </TabPanel>
          <TabPanel v-if="$can('pharmasan.ventas.contrato-precio.crear')" header="Soportes">
            <Soportes/>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </section>
</template>
<script>
  import { ref, defineAsyncComponent, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useContratosStore } from '../../stores/contratos.store'
  export default {
    name: 'listasDePrecio',
    components: {
      Zonificacion: defineAsyncComponent(() => import('./components/zonificacion.vue')),
      Pacientes: defineAsyncComponent(() => import('./components/pacientes.vue')),
      Precios: defineAsyncComponent(() => import('./components/articulos.vue')),
      Soportes: defineAsyncComponent(() => import('./components/soportes.vue'))
    },
    setup () {
      const route = useRoute()
      const router = useRouter()
      const contratosStore = useContratosStore()
      const listas = ref([
        {
          name: 'Lista de prueba basada en el contrato x',
          code: 1
        }
      ])
      const expandedRows = ref([])
      const onClickejemplo = () => {
        // window.localStorage.setItem('miLocalMariaP', JSON.stringify({ ...contratosStore._contrato }))
        router.push({ name: 'pharmasan.ventas.contrato-precio.crear', query: { copiar: route.params.id } })
      }
      onMounted(() => {
        contratosStore.findContrato(route.params.id)
      })
      return {
        expandedRows,
        listas,
        contratosStore,
        onClickejemplo
      }
    }
  }
</script>
<style scoped>
</style>
